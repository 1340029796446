exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alcohol-js": () => import("./../../../src/pages/alcohol.js" /* webpackChunkName: "component---src-pages-alcohol-js" */),
  "component---src-pages-drugs-js": () => import("./../../../src/pages/drugs.js" /* webpackChunkName: "component---src-pages-drugs-js" */),
  "component---src-pages-fentanyl-js": () => import("./../../../src/pages/fentanyl.js" /* webpackChunkName: "component---src-pages-fentanyl-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-l-1-js": () => import("./../../../src/pages/l1.js" /* webpackChunkName: "component---src-pages-l-1-js" */),
  "component---src-pages-la-js": () => import("./../../../src/pages/la.js" /* webpackChunkName: "component---src-pages-la-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

