import '~/assets/styles/index.scss';

import React from 'react';
import { GeoLocationProvider } from '~/app/context';

export const wrapRootElement = ({ element }) => {
  return <GeoLocationProvider>{element}</GeoLocationProvider>;
};

setTimeout(() => {
  if (document.getElementById('ctm_form')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A97E0E1D8EACC84D2272A9EB2664A87BA5DAE921762C2E002', // this FormReactor
        document.getElementById('ctm_form'), // ID of the web form you want to attach your FormReactor
        '8776296777', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'first_name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: ['last_name', 'insurance', 'membership', 'birth_date', 'service_phone', 'front_card', 'back_card'], // list of additional field IDs in the form you want to capture
        }
      );
    });
  }
  if (document.getElementById('quiz_form')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A97E0E1D8EACC84D2C4B52AE1726A3A5A9B55534F2C9CC9B9', // this FormReactor
        document.getElementById('quiz_form'), // ID of the web form you want to attach your FormReactor
        '8776296777', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name_quiz', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone_quiz', // ID of the lead phone number field
          email: 'email_quiz',
          fields: ['last_name_quiz', 'insurance_quiz', 'member_quiz', 'birthdate_quiz', 'group_quiz', 'message_quiz'], // list of additional field IDs in the form you want to capture
        }
      );
    });
  }
}, 1500);

// function checkHeaderPosition() {
//   var headerSticky = document.querySelector('.show-mob');
//   var headerInfo = headerSticky.querySelector('.header__info');
//   var viewportOffset = headerSticky.getBoundingClientRect();
//   var top = viewportOffset.top;
//   if (headerSticky.offsetParent !== null) {
//     if (top < -30) {
//       headerInfo.classList.add('header__info-top');
//     } else {
//       headerInfo.classList.remove('header__info-top');
//     }
//   }
// }

// setTimeout(() => {
//   checkHeaderPosition();
//   document.addEventListener('scroll', () => {
//     checkHeaderPosition();
//   });
//   window.addEventListener('resize', () => {
//     checkHeaderPosition();
//   });
// }, 1000);

var element = document.querySelector('a[href="https://legitscript.com"]');
var parent = element.parentNode;
var wrapper = document.createElement('div');
wrapper.classList.add('hidden');
parent.replaceChild(wrapper, element);
wrapper.appendChild(element);
var legScript = wrapper.innerHTML;
setTimeout(() => {
  var bannerLicenses = document.querySelectorAll('.banner__licenses');
  var footerLicenses = document.querySelector('.footer__licenses');
  var certifiedLicenses = document.querySelector('.js-legit');
  if (bannerLicenses.length) {
    bannerLicenses.forEach((e) => {
      e.insertAdjacentHTML('beforeend', legScript);
    });
  }

  if (footerLicenses) {
    footerLicenses.insertAdjacentHTML('afterBegin', legScript);
  }

  if (certifiedLicenses) {
    certifiedLicenses.insertAdjacentHTML('afterBegin', legScript);
  }

  wrapper.remove();
}, 1000);
